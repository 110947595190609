.content-left-analitic {
  width: 40%;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 15px;
}
.content-right-analitic {
  width: 60%;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 15px;
}

.mounth-graphics {
  width: 100%;
  height: 350px;
}

.ui.container-table {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 1em 1em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  height: 400px;
  overflow-y: auto;
}

.ui.table {
  margin: 1rem 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0;
  font-size: 12px;
  text-align: center;
  border-right: none;
}

.ui.table thead th {
  background: #f9fafb;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-left: none;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.table tbody th {
  height: 350px;
  overflow-y: auto;
}

.ui.table tbody tr td {
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  text-transform: uppercase;
}
