.contentInput {
  display: flex;
  flex-direction: column;
  width: 25%;
}

body {
  overflow: auto;
}

.info-extract {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d4d4d5;
  padding: 10px 8px;
}

.contentInput input {
  outline: none;
}

.contentInput label {
  font-weight: 400;
}

.select-defect {
  outline: none;
  padding: 8px 15px;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.button-defect-search {
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: rgba(163, 142, 23, 0.8);
  font-size: 14px;
  color: white;
  text-align: center;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 500;
}
.button-defect-search:disabled{
  opacity: 0.4;
  cursor: not-allowed;
}

.content-left {
  width: 50%;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 15px;
}

.content-right {
  width: 50%;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 15px;
}

.title-graphic {
  background: #f3f4f5;
  border: 1px solid #d4d4d5;
  border-radius: 4px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 0;
  margin-top: 15px;
}

.filter-graphic {
  background: #fff;
  border: 1px solid #d4d4d5;
  border-radius: 4px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 15px 0;
}

.filter-graphic p {
    font-weight: 500;
    color: black;
}

.content-filter-graph {
    display: flex;
    margin-left: 15px;
}

.div-input-filter-graph {
    display: flex;
    align-items: center;
    margin: 0 10px;
    width: 80px;
}

.div-input-filter-graph label {
    margin: 0;
    font-weight: normal;
}
.div-input-filter-graph input {
    margin: 0;
    margin-right: 6px;
}