.content-resume {
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.container-info {
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.box-info {
  background-color: #f0eeee;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 4px;
  padding: 20px;
  margin: 10px;
  flex: 1;
  justify-content: space-between;
}

.box-info p {
  color: #888888;
  font-size: 12px;
}

.box-info h3 {
  color: #222222;
  font-size: 40px;
}
