.select-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    width: 100%;
  }
  
  .title-select {
    margin-bottom: 8px;
    color: rgb(85, 85, 85);
    font-weight: bold;
  }
  
  .select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #dddddd;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    min-width: 200px;
    background-color: #fff;
  }
  
  .selected-value {
    margin-right: 8px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  
  .remove-selected-value {
    margin-left: 8px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    color: #ff0000;
  }
  
  .caret {
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #000000 transparent;
    position: relative;
    margin-left: auto;
  }
  
  .options-container {
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1;
    height: 12vw;
    overflow-y: auto;
  }
  
  .option {
    padding: 8px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #f5f5f5;
  }
  
  .selected {
    background-color: #f5f5f5;
  }
  
  .backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }