.container-info-mensal {
  margin-top: 15px;
  border: 1px solid #d4d4d4;
  padding: 10px 15px;
  border-radius: 4px;
}

.list-style-mensal {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  padding: 8px 25px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.title-name-sede{
    display: flex;
}

.title-name-sede:hover{
    background-color: transparent
}

.list-style-mensal:nth-last-child(-n + 1) {
  border-bottom: none;
}

ul:hover {
  background-color: transparent;
}

li:hover {
  background-color: transparent;
}

.list-sede {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  padding: 8px 25px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  margin: 10px 0;
}

.open-sede{
    display: flex;
    flex-direction: row;
}

.name-sede:hover {
  background-color: transparent;
  font-weight: bold;
  color: black;
  transition: all ease 0.3s;
}

.name-sede {
  margin: 0;
}

.table-sede{
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.28571429rem;
    text-align: left;
    color: rgba(0,0,0,.87);
    border-collapse: separate;
    border-spacing: 0;
    border-left: none;
    border-bottom: none;
}

.table-sede:hover {
    background: #fff;
  }
  
  .header-sede:hover {
    background-color: transparent;
  }

.table-head tr{
    text-align: center;
}

.table-head tr th {
    cursor: auto;
    background: #f9fafb;
    text-align: inherit;
    color: rgba(0,0,0,.87);
    padding: 0.92857143em 0.78571429em;
    vertical-align: inherit;
    font-weight: 700;
    text-transform: none;
    border-bottom: 1px solid rgba(34,36,38,.1);
    border-left: 1px solid rgba(34,36,38,.1);
}

.table-head tr th:hover{
    background: #f9fafb;
}

.table-body{
    cursor: default;
}

.table-body:hover{
    background: #fff;
}

.table-body tr{
    padding: 0.5em 0.7em;
}

.table-body tr:hover{
    background-color: transparent;
}

.table-body tr td{
    border-left: 1px solid rgba(34,36,38,.1);
    border-bottom: 1px solid rgba(34,36,38,.1);
    padding: 0.5em 0.7em;
}

.table-body tr td:hover{
    background-color: transparent;
}

.pqmf{
    background-color: rgba(163, 142, 23, 0.8);
    font-weight: bold;
    right: 3.625rem;
    margin-right: -1.2em;
    position: relative;
    border-radius: 0 0.28571429rem 0.28571429rem 0;
    border-color: rgba(0,0,0,.15);
    font-size: 1.3rem;
    display: inline-block;
    line-height: 1;
    padding: 0.8rem 0.8rem 0.8rem 10%;
    color: rgba(0,0,0,.6);
    border: 0 solid transparent;
    width: 25%;
    cursor:default;
}



.pqmf:hover{
    background-color: rgba(163, 142, 23, 0.8);
}