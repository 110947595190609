.modal-overlay {
    z-index: 9999;
    width: 80vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-box {
    display: block;
    background: white;
    width: 80%;
    height: 80%;
    /* padding: 1rem; */
    border-radius: 1rem;
}