.contentInput {
    display: flex;
    flex-direction: column;
    width: 25%;
  }
  
  .info-compare {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d4d4d5;
    padding: 10px 8px;
  }
  
  .contentInput input {
    outline: none;
  }
  
  .contentInput label {
    font-weight: 400;
  }
  
  .select-defect {
    outline: none;
    text-align: left;
    padding: 8px 15px;
    height: 40px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #fff;
  }

  .select-forn {
    outline: none;
    padding: 14px 0;
    height: 40px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .button-defect-search {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background: rgba(163, 142, 23, 0.8);
    font-size: 14px;
    color: white;
    text-align: center;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: 500;
  }
  
  .content-left-compare {
    padding: 0 15px;
  }
  
  .title-graphic {
    background: #f3f4f5;
    border: 1px solid #d4d4d5;
    border-radius: 4px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin: 0;
    margin-top: 15px;
  }

  .content-options-select {
    background: #FFF;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.content-options-select button {
    background: none;
    outline: none;
    border: none;
    text-align: start;
    padding: 10px 53px;
    color: #888888;
}

.content-options-select button:hover {
    background-color: rgba(0,0,0,.05);
    width: 100%;
    transition: .3s;
}