textarea {
    width: '100%';
    /* resize: none; */
    resize: vertical;
}

legend {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

.responsive-iframe {
    position: relative;
    width: 100%;
    height: 50rem;
    overflow: auto;
    border: none;
}

.responsive-iframe img{
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    border: none;
}

.label-file {
    background-color: #3498db;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    padding: 6px 20px
  }