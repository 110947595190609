body {
  overflow: hidden;
}

.DivGeneral {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.errorSend {
  background: #ffff;
  height: 120px;
  box-shadow: 0 2px 2px 0 rgb(50 50 50 / 15%);
  font-family: Lato;
  display: flex;
  padding: 0px 10px;
  margin: 50px 0;
}

.errorSend div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.errorSend button {
  width: 55%;
}

.errorSend span {
  margin-bottom: 16px;
  font-size: 18px;
}

.BtnClean {
  display: flex;
  align-self: self-end;
  font-size: 15px;
  color: white;
  border: none;
  background: rgba(183, 152, 97, 0.7);
  text-transform: uppercase;
  padding: 12px 33px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s;
}

.modalSignUp {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 10px;
  width: 100%;
}

.contentList {
  width: 100%;
  background: #ffffff;
  margin: 15px 0px -15px;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  color: black;
  padding: 10px 0;
  border-bottom: 2px solid #dddddd;
}

.listProperty {
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
}
.content {
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 15px;
  overflow-y: scroll;
  height: 65vh;
  margin-top: 15px;
}

.listTd {
  color: black;
  text-transform: uppercase;
  padding: auto 8px;
  width: 20%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.deleteBtn {
  color: black;
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 5px 8px;
  width: 20%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  transition: all 0.2s;
  border: none;
  margin-right: 3px;
  cursor: pointer;
}

.modal-signIn {
  width: 50%;
  margin-top: 10%;
  background: #ebe8e8;
  position: absolute;
  z-index: 3;
}

.editBtn {
  color: black;
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 5px 8px;
  width: 10%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  transition: all 0.2s;
  border: none;
  margin-right: 3px;
  cursor: pointer;
  flex: 1;
}

.deleteUserBtn {
  color: black;
  text-transform: uppercase;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 5px 8px;
  width: 10%;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  transition: all 0.2s;
  border: none;
  margin-right: 3px;
  cursor: pointer;
  flex: 1;
}

.content-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
}

.content-details h1 {
  font-size: 25px;
  font-weight: normal;
  border-bottom: 2px solid #b4b4b4;
  width: 45%;
  text-align: center;
}

.delete {
  box-shadow: 0 2px 2px 0 rgb(50 50 50 / 15%);
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 15px 15px;
}

.delete h1 {
  margin: 0;
}

.button-confirm {
  width: fit-content;
  padding: 5px 10px;
  margin: 0 5px;
  background: none;
  outline: none;
  border: none;
}

.div-button-confirm {
  display: flex;
  align-self: flex-end;
}

.content-header-button-and-title {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.content-header-button-and-title h1 {
  border-bottom: 1px solid rgb(198, 193, 193);
}

.dimmed-row {
  opacity: 0.5;
}

.dimmed-row input {
  background-color: #f9f9f9;
}

/* Garantir que a tabela ocupe a largura completa e não quebre */
.table-responsive {
  width: 100%;
  overflow-x: auto; /* Permite rolagem horizontal quando necessário */
  -webkit-overflow-scrolling: touch; /* Melhora a rolagem em dispositivos iOS */
}

/* Tabela sem paginação e rolável */
.table {
  width: 100%;
  table-layout: fixed; /* Previne que a tabela quebre e force um scroll horizontal */
  overflow: hidden;
}
/* Estilo para garantir que a tabela tenha rolagem */
.table-container {
  max-height: calc(100vh - 150px); /* Isso garante que a tabela tenha rolagem se necessário */
  overflow-y: auto;
}

/* Botão fixo no rodapé com o sticky */
.sticky-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* sombra para dar destaque */
  z-index: 10; /* Garante que o botão fique acima de outros elementos */
}

.sticky-footer .btn {
  width: 100%; /* O botão ocupa toda a largura */
}

/* Estilo para a aba ativa */
.nav-link.active {
  background-color: #866437d0; /* Cor de fundo para a aba ativa */
  color: white; /* Cor do texto da aba ativa */
  font-weight: bold; /* Deixa o texto em negrito */
}
.nav-link {
  margin-top: 40px;;
  background-color: #91919191; /* Cor de fundo para a aba ativa */
  color: white; /* Cor do texto da aba ativa */
  font-weight: bold; /* Deixa o texto em negrito */
}
.nav-link.active:hover {
  background-color: #523a1ad0; /* Cor de fundo para a aba ativa */
  font-weight: bold; /* Deixa o texto em negrito */
}