.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-dialog {
    max-width: 90%;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .modal-title {
    font-weight: bold;
    margin-right: auto;
  }
  
  .btn-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    color: #721c24;
  }
  
  .modal-body {
    padding: 10px;
  }
  
  .message {
    font-size: 1.2rem;
    margin: 0;
  }
  
  @media screen and (max-width: 768px) {
    .modal-dialog {
      max-width: 95%;
    }
  }