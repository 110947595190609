.info-nce {
  padding: 15px;
  background-color: #f7f7f7;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
}

.content-select {
  width: 50%;
}

.ls-select {
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.button-search-nce {
  width: 100%;
  border-radius: 4px;
  background: #fff;
  color: black;
  outline: none;
  border: 1px solid #b2b2b2;
  padding: 4px 10px;
  height: fit-content;
}

.button-search-nce:hover {
  background-color: #dddddd;
  transition: all ease 0.2s;
}

.content {
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 15px;
  overflow-y: scroll;
  height: 500px;
  margin-top: 15px;
}

.contentListNce {
  margin: 5px 0px;
  background-color: none;
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  color: black;
  padding: 10px 0;
  border-bottom: 2px solid #dddddd;
}

.listProperty {
  width: 100%;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
}

.listTd {
  color: black;
  text-transform: uppercase;
  padding: auto 8px;
  width: 20%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.button-details {
  padding: 2px 6px;
  background-color: #fff;
  border: 1px solid #b2b2b2;
  border-radius: 4px;
  outline: none;
}

.button-details:hover {
  background-color: #e5e5e5;
  transition: all ease 0.3s;
}

.Dropdown {
  position: relative;
  right: 0;
  width: 0;
}

.modal-options {
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  background-color: white;
  width: 25vh;
  position: relative;
  top: 5vh;
  right: 28vh;
  border-radius: 4px;
}

.public-button {
  color: #a38e17;
  font-size: 14px;
  padding: 2px 20px;
  background: none;
  border: none;
  outline: none;
  text-align: left;
}

.public-button:hover {
  background-color: #a38e17;
  color: white;
  transition: all ease 0.3s;
}

.delete-button {
  color: #d75553;
  font-size: 14px;
  padding: 2px 20px;
  background: none;
  border: none;
  outline: none;
  text-align: left;
}

.delete-button:hover {
  background-color: #d75553;
  color: white;
  transition: all ease 0.3s;
}

.flex-box {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.content-box {
  height: auto;
  width: 25%;
  background-color: #FFF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 12px;
}

.title-id-nce {
  text-transform: uppercase;
  font-size: 16px;
}

.close-modal-status {
  background: none;
  outline: none;
  border: none;
}

.content-list-status {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 15px 0;
}

.content-list-status li {
  margin: 8px 0;
  list-style: none;
  display: flex;
  align-items: center;
}

svg:hover {
  background-color: transparent;
}

@media (max-width: 1366px) {
  .content{
    height: 57vh;
  }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
  
  .modal-nce {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 470px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 2;
    border-radius: 5px;
  }

  .modal-nce-delete {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 280px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 2;
    border-radius: 5px;
  }
  

  
  .modal h1 {
    margin-top: 0;
  }
  
  .modal button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #666;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .modal button:hover {
    color: #000;
  }
  
  .modal textarea {
    width: 100%;
    height: 80px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }
  
  .modal button[type="submit"] {
    background-color: #008000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .modal button[type="submit"]:hover {
    background-color: #006600;
  }
  