.row > div {
    padding: 10px;
}

input, select, textarea {
    margin: 0 1rem 0 0;
    width: 90%;
    border-top: none;
    border-left: none;
    padding: 0.5rem;
}

label {
    width: 30rem;
}

input, select, th {
    text-align: center;
}

.ckeckpoint label {
    width: 15rem;
}

.ckeckpoint input {
    width: 3rem;
}

.modal-body {
    max-height: calc(100vh - 50%);
    overflow-y: auto;
    /* row-gap: 0;
    column-gap: 0; */
}

form {
    max-height: calc(100vh - 40%);
    overflow-y: auto;
    overflow-x: scroll;
    padding: 0 1rem;
   
}

.modal-header {
    position: sticky;
    top: 0;
    background-color: white; 
    z-index: 1055;
    margin: 0;
}

/* .table {
    border-spacing: 0px;
} */

/* < 992 fica um abaixo do outro */