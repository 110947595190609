.logo-notify {
    min-width: 150px;
    max-width: 300px;
    width: 260px;
    height: 184px;
    flex: 1;
}

.content-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.button-login {
    background: none;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    width: 190px;
    padding: 6px 0;
    font-size: 16px;
    color: #b8b8b8;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
    margin: 20px 0;
}

.logo-petropolis {
    width: 160px;
    flex: 1;
}

.content-logo-petropolis {
    margin-top: 15px;
}

.input-login {
    width: auto;
    margin: 15px 0;
    border: 1px solid #eaeaea;
    padding: 6px 5px;
    border-radius: 6px;
    flex: 1;
}
.resetPassword {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    font-size: small;
    padding-bottom: 2vh;
    color: rgb(219, 97, 97);
    
}
.resetPassword:hover{
    color: rgb(204, 0, 0);
}

input::placeholder {
    color : #b8b8b8;
    text-align: center;
}

body {
    background-color: #f7f7f7;
}

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn-log {
    background-color: #827121;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 4px;
}

.code-input {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 2px solid grey;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 15px;
}

.grecaptcha-badge {
    position: fixed ;
    left: 0px ;
    /* bottom: 0px ; */
    z-index: 9999;
}