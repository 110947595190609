.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 5% 0;
}

.Container label {
  font-weight: bold;
  font-size: 15px;
}

.divInput {
  position: relative;
  padding-top: 13px;
  margin: 12px 0;
  width: 65%;
}

.cpf-cnpj-input--error {
  border: 1px solid red;
}

.cpf-cnpj-input__error-message {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

.divInput input button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.divInput input:focus {
  border-bottom: 1px solid #66afe9;
}

.divInput label {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 0;
}

.divInput input ~ label {
  transition: 0.4s;
}

.divInput input:focus ~ label {
  color: #66afe9;
  transform: translateY(-15px);
}

.divInput input:valid ~ label {
  color: #66afe9;
  transform: translateY(-15px);
}

.input-sign-in {
  outline: 0;
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  background: none;
  font-size: 18px;
  text-align: left;
}

.button-send {
  font-size: 20px;
  color: white;
  border: none;
  background: rgba(242, 182, 18, 0.7);
  text-transform: uppercase;
  padding: 12px 33px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s;
}

.button-send:hover {
  background: #83620e;
}

.content-button-send {
  width: 50%;
  margin: 0 5px;
}

.Input-Select {
  border-bottom: 1px solid black;
}

.unity-style {
  background-color: #5b5a5a;
  border-radius: 8px;
  margin: 0 10px;
  padding: 6px 10px;
}

.unity-style p {
  margin: 0;
  padding: 6px 10px;
  color: white;
  font-weight: 600;
}

.content-edit {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.info-content-edit {
  height: 40vh;
  width: 40%;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-content-details {
  height: auto;
  max-height: 300px;
  width: 50%;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 20px;
  overflow-y: auto;
}

.edit-input {
  display: flex;
  flex-direction: column;
  align-self: normal;
  margin: 25px 0;
}

.edit-input input {
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  border-bottom: 1px solid grey;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 5px;
}

.content-button {
  display: flex;
  align-self: self-end;
}

.content-button button {
  margin: 8px 8px;
  padding: 12px 12px;
  border: none;
  outline: none;
}

.back-edit-unity-button {
}

.delete-edit-unity-button {
}

.button-select-defeito {
  display: flex;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid grey;
  padding: 10px 8px;
}

.button-select-defeito:hover {
  transition: all ease 0.2s;
  background-color: rgba(242, 182, 18, 0.7);
  color: white;
}

.content-loading {
  height: auto;
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
