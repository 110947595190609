.container-header {
    background-color: #f5f4f4;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 1;
}
.content-header-info {
    background-color: #dedede;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
}

.content-header-info p {
    margin: 0;
    color: #555555;
    font-weight: 500;
}

.content-options {
    position: absolute;
    background: #222222;
    right: 0;
    display: flex;
    flex-direction: column;
}

.content-options button {
    background: none;
    outline: none;
    border: none;
    text-align: start;
    padding: 10px 53px;
    color: #888888;
}

.content-options button:hover {
    background-color: #827112;
    width: 100%;
    transition: .3s;
    color: white;
}